<div class="test-drag" cdkDrag
     (cdkDragStarted)="onDragStarted($event)"
     (cdkDragEnded)="onDragEnded($event)">
   <div class="drag-bar"></div>
   <div class="drag-bar"></div>
   <app-button-bar-button class="button-cmp" title="Refresh. Use the refresh button if the page times out while loading. (This could happen if your internet connection is slow.)">
      <i class="fas fa-redo-alt" style="font-size:1.75rem;" (click)="handleClickRefresh()"></i>
   </app-button-bar-button>
   <app-button-bar-button class="button-cmp" title="Restart. Use the Restart button if your exam does not begin after a few minutes.">
      <i class="fas fa-sync-alt" style="font-size:1.75rem;" (click)="handleClickRestart()"></i>
   </app-button-bar-button>
   <!--<app-button-bar-button class="button-cmp" title="This will show copyright and other legal notices when implemented.">
      <img src="../../assets/Images/Legal.png" />
   </app-button-bar-button>
   <app-button-bar-button class="button-cmp" title="This will terminate the exam when implemented.">
      <img src="../../assets/Images/Exit.png" />
   </app-button-bar-button>-->
</div>
