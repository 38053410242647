import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SignalRService } from '../Services/signal-r.service';

@Component({
   selector: 'lockdown',
   template: ''
})
export class Lockdown implements OnInit {

   constructor(private _signalR: SignalRService, private _route: ActivatedRoute) { }

   ngOnInit(): void {
      this._signalR.startConnection();
   }
}
