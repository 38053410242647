import { Component, OnInit } from '@angular/core'
import { BrowserLockService } from '../Services/browser-lock-service';

@Component({
   selector: 'insecure-screen',
   templateUrl: './insecure-screen.component.html'
})
export class InsecureScreen implements OnInit {
   public appName: string = '';

   constructor(private _browserLock: BrowserLockService) {

   }

   public ngOnInit() {
      this.appName = this._browserLock.getAppName();
   }
}
