import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MachineComponent } from './machine/machine.component';
import { Lockdown } from './Lockdown/lockdown.component';
import { Restarting } from './Restarting/restarting.component';
import { InsecureScreen } from './InsecureScreen/insecure-screen.component';
import { Loading } from './Loading/loading.component';
import { WaitSpinner } from './WaitSpinner/wait-spinner.component';
import { RestartConfirmationDialogComponent } from './restart-confirmation-dialog/restart-confirmation-dialog.component';
import { DraggableButtonBarComponent } from './draggable-button-bar/draggable-button-bar.component';
import { ButtonBarButtonComponent } from './button-bar-button/button-bar-button.component';

@NgModule({
   declarations: [
      AppComponent,
      HomeComponent,
      MachineComponent,
      Lockdown,
      Restarting,
      InsecureScreen,
      Loading,
      WaitSpinner,
      RestartConfirmationDialogComponent,
      DraggableButtonBarComponent,
      ButtonBarButtonComponent,
   ],
   imports: [
      BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
      HttpClientModule,
      RouterModule.forRoot([
         { path: '', component: HomeComponent, pathMatch: 'full' },
      ]),
      BrowserAnimationsModule,
      MatDialogModule,
      DragDropModule
   ],
   providers: [],
   bootstrap: [AppComponent]
})
export class AppModule { }
