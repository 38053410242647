import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
   selector: 'app-button-bar-button',
   templateUrl: './button-bar-button.component.html',
   styleUrls: ['./button-bar-button.component.scss']
})
export class ButtonBarButtonComponent implements OnInit {

   @Output() click = new EventEmitter<void>();

   constructor() { }

   ngOnInit(): void {
   }

   handleClick(): void {
      this.click.emit();
   }
}
