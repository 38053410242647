import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { BrowserLockService } from '../Services/browser-lock-service';
import { MatDialog } from '@angular/material/dialog';
import { DialogResult, RestartConfirmationDialogComponent } from '../restart-confirmation-dialog/restart-confirmation-dialog.component';
import { VendorServiceService } from '../Services/vendor-service.service';
import { MyMonitoringService } from '../Services/logging.service';
import { SignalRService } from '../Services/signal-r.service';

//TODO: Break this up into smaller components.
@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.scss']
})
export class HomeComponent {
   public restarting: boolean = false;
   public inSecureBrowser: boolean = false;
   public error: string = "";
   public hasError: boolean = false;

   public loading: boolean = true;
   public loadingBrowser: boolean = true;
   public loadingMachine: boolean = true;

   @Input() pointerEvents = 'auto';
   @ViewChild('machineCmp') machineCmp: any;

   constructor(
      @Inject('BASE_URL') public baseUrl: string,
      private _browserLock: BrowserLockService,
      private _dialog: MatDialog,
      private _vendorService: VendorServiceService,
      private _signalRService: SignalRService,
      private _loggingService: MyMonitoringService) {
   }

   ngOnInit(): void {
      this._browserLock.isSecureBrowser()
         .then((isSecure) => {
            this.loadingBrowser = false;
            this.updateLoading();
            this.inSecureBrowser = isSecure;
         })
         .catch(error => this.showError(error));

      this._signalRService.subscribeToExamReady(() => {
         this.loadingMachine = false;
         this.updateLoading();
      });
   }

   private updateLoading(): void {
      this.loading = this.loadingBrowser || this.loadingMachine;
   }

   public handleClickRestart(): void {
      let dlgRef = this._dialog.open(RestartConfirmationDialogComponent, { height: '14rem', width: '28rem' });
      dlgRef.afterClosed().subscribe(result => {
         if (result === DialogResult.yes)
            this.restart();
      });
   }

   private restart(): void {
      this.restarting = true;

      //Once we start the restart process, we are out of machine-loading mode and in machine-restarting mode.
      this.loadingMachine = false;
      this.updateLoading();

      this._vendorService.restartMachine()
         .then(() => { this.restarting = false })
         .catch(error => {
            this.showError(error);
            this.logToAppinsights(error);
         });
   }

   private showError(error: string) {
      this.error = error;
      this.hasError = true;
   }

   public handleClickRefresh(): void {
      this.machineCmp.refresh();
   }

   public handleDragStarted() {
      this.pointerEvents = 'none';
   }

   public handleDragEnded() {
      this.pointerEvents = 'auto';
   }

   public logToAppinsights(error: string) {
      this._loggingService.logTrace(error);
   }
}
