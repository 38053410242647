import { Injectable, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface IAppServiceUrls {
   lmUrl: string;
   vsUrl: string;
   efhDomain: string;
}

@Injectable({
   providedIn: 'root'
})
export class AppService implements OnInit {
   private _lmUrl: string = '';
   private _vsUrl: string = '';
   private _efhDomain: string = '';

   constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

   ngOnInit(): void {
      this.http
         .get<IAppServiceUrls>(this.baseUrl + 'services')
         .subscribe(
            result => {
               this._lmUrl = result.lmUrl;
               this._vsUrl = result.vsUrl;
               this._efhDomain = result.efhDomain;
            },
            error => {
               console.log(error);
            });
   }

   public getLabManagerUrl(): Promise<string> {
      return this.getValue(() => this._lmUrl);
   }

   public getVendorServiceUrl(): Promise<string> {
      return this.getValue(() => this._vsUrl);
   }

   public getExamsFromHomeDomain(): Promise<string> {
      return this.getValue(() => this._efhDomain);
   }

   private getValue(val: () => string): Promise<string> {
      return new Promise<string>((resolve, reject) => {
         if (val() != '') {
            resolve(val());
         }
         else {
            this.LoadValues()
               .then(() => { resolve(val()); })
               .catch((e) => { reject(e); });
         }
      });
   }

   private LoadValues(): Promise<void> {
      return new Promise<void>((resolve, reject) => {
         this.http
            .get<IAppServiceUrls>(this.baseUrl + 'services')
            .subscribe(
               result => {
                  this._lmUrl = result.lmUrl;
                  this._vsUrl = result.vsUrl;
                  this._efhDomain = result.efhDomain;
                  resolve();
               },
               error => {
                  console.log(error);
                  reject(error);
               });
      });
   }
}
