import { Injectable } from '@angular/core';

export enum HttpResultType {
   success,
   error,
   timeout
}

@Injectable({
   providedIn: 'root'
})
export class HttpConnectorService {
   constructor() { }

   get(url: string, timeout: number): Promise<void> {
      return new Promise((resolve, reject) => {
         let xhr = new XMLHttpRequest();
         xhr.timeout = timeout;
         xhr.open('GET', url);

         xhr.addEventListener('load', e => {
            resolve();
         });

         xhr.addEventListener('error', e => {
            reject();
         });

         xhr.addEventListener('timeout', e => {
            reject();
         });

         xhr.send();
      });
   }

   post(url: string, timeout: number): Promise<void> {
      return new Promise((resolve, reject) => {
         let xhr = new XMLHttpRequest();
         xhr.timeout = timeout;
         xhr.open('POST', url);

         xhr.addEventListener('load', e => {
            resolve();
         });

         xhr.addEventListener('error', e => {
            reject();
         });

         xhr.addEventListener('timeout', e => {
            reject();
         });

         xhr.send();
      });
   }

   iterativePost(url: string, timeout: number): Promise<HttpResultType> {
      return this.iterativeSend(url, timeout, 'POST');
   }

   iterativeGet(url: string, timeout: number): Promise<HttpResultType> {
      return this.iterativeSend(url, timeout, 'GET');
   }

   private iterativeSend(url: string, timeout: number, verb: string): Promise<HttpResultType> {
      let callTime: number = Date.now();
      return this.iterativeSendImpl(url, timeout, callTime, verb);
   }

   private iterativeSendImpl(url: string, timeout: number, callTime: number, verb: string): Promise<HttpResultType> {

      return new Promise((resolve, reject) => {
         this.singleSend(url, verb)
            .then(r => {
               let returnTime = Date.now();
               if (r === HttpResultType.success) {
                  resolve(HttpResultType.success);
               }
               else if (r === HttpResultType.timeout) {
                  let timeUsed = returnTime - callTime;
                  if (timeout - timeUsed > 0) {
                     this.iterativeSendImpl(url, timeout - timeUsed, callTime, verb)
                        .then(r => {
                           resolve(r);
                        })
                        .catch(e => reject());
                  }
                  else {
                     reject();
                  }
               }
            })
            .catch(e => reject());
      });
   }

   private singleSend(url: string, verb: string): Promise<HttpResultType> {
      return new Promise((resolve, reject) => {
         let xhr = new XMLHttpRequest();
         xhr.timeout = 90000;
         xhr.open(verb, url);

         xhr.addEventListener('load', e => {
            resolve(HttpResultType.success);
         });

         xhr.addEventListener('error', e => {
            reject();
         });

         xhr.addEventListener('timeout', e => {
            resolve(HttpResultType.timeout);
         });

         xhr.send();
      });
   }
}
