<div class="restart-confirmation-dialog">
   <div class="header-row">
      <h2 class="" mat-dialog-title>Restart Exam Computer</h2>
   </div>

   <div class="body-row">
      <p>Are you sure you want to restart the machine running the exam?</p>
   </div>
   
   <div class="footer-row">
      <div (click)="onClickNo()" [ngClass]="'ctp-button ctp-primary'" title="No">
         No
      </div>
      <div (click)="onClickYes()" [ngClass]="'ctp-button ctp-primary'" title="Yes">
         Yes
      </div>
   </div></div>
