import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../environments/environment';
import { HttpConnectorService } from './http-connector.service';

@Injectable({
   providedIn: 'root'
})
export class VendorServiceService {

   constructor(
      private route: ActivatedRoute,
      private http: HttpClient,
      private httpConnector: HttpConnectorService) { }

   restartMachine(): Promise<void> {
      return new Promise<void>((resolve, reject) => {
         const url = environment.ibtVendorServiceUrl;
         this.route.queryParams
            .subscribe(params => {
               let symlink = params['symlink'];
               let restartUrl: string = url + 'symlinks/' + symlink + '/restart';
               this.httpConnector
                  .iterativePost(restartUrl, 320000)
                  .then(() => resolve())
                  .catch(error => reject('Failed to restart.' /*+ ' Restart URL -- ' + restartUrl*/));
            });
      });
   }

   waitForReady(): Promise<void> {
      return new Promise<void>((resolve, reject) => {
         const url = environment.ibtVendorServiceUrl;
         this.route.queryParams
            .subscribe(params => {
               let symlink = params['symlink'];
               let awaitUrl: string = url + 'symlinks/' + symlink + '/waitForReady';
               this.httpConnector
                  .iterativeGet(awaitUrl, 320000)
                  .then(r => resolve())
                  .catch(error => reject('The machine failed to start.'));
            });
      });
   }
}
