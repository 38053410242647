import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { VendorServiceService } from '../Services/vendor-service.service';

@Component({
   selector: 'app-draggable-button-bar',
   templateUrl: './draggable-button-bar.component.html',
   styleUrls: ['./draggable-button-bar.component.scss']
})
export class DraggableButtonBarComponent implements OnInit {
   @Output() dragStarted = new EventEmitter<void>();
   @Output() dragEnded = new EventEmitter<void>();
   @Output() clickRestart = new EventEmitter<void>();
   @Output() clickRefresh = new EventEmitter<void>();

   constructor() {

   }

   ngOnInit(): void {
   }

   public onDragStarted(event: any) {
      this.dragStarted.emit();
   }

   public onDragEnded(event: any) {
      this.dragEnded.emit();
   }

   handleClickRestart(): void {
      this.clickRestart.emit();
   }

   handleClickRefresh(): void {
      this.clickRefresh.emit();
   }
}
