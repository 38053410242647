<div id="home-container">
   <div id="loading-container" *ngIf="loadingBrowser || (loadingMachine && inSecureBrowser)">
      <app-loading></app-loading>
   </div>
   <insecure-screen *ngIf="!loadingBrowser && !inSecureBrowser"></insecure-screen>
   <app-restarting *ngIf="restarting && inSecureBrowser"></app-restarting>
   <div [ngClass]="'machine-container'" *ngIf="!loading && !restarting && inSecureBrowser">
      <app-machine *ngIf="!restarting"
                   [pointerEvents]="pointerEvents"
                   #machineCmp></app-machine>
   </div>
   <app-draggable-button-bar *ngIf="!loadingBrowser && inSecureBrowser"
                             (dragStarted)="handleDragStarted()"
                             (dragEnded)="handleDragEnded()"
                             (clickRestart)="handleClickRestart()"
                             (clickRefresh)="handleClickRefresh()"></app-draggable-button-bar>
   <p class="driver-error" *ngIf="hasError">{{error}}</p>
   <lockdown></lockdown>
</div>
