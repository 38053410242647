import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export enum DialogResult {
   no = 0,
   yes = 1
};

@Component({
   selector: 'app-restart-confirmation-dialog',
   templateUrl: './restart-confirmation-dialog.component.html'
})
export class RestartConfirmationDialogComponent implements OnInit {

   constructor(private _dlgRef: MatDialogRef<RestartConfirmationDialogComponent>) { }

   ngOnInit(): void {
   }

   public onClickYes(): void {
      this._dlgRef.close(DialogResult.yes);
   }

   public onClickNo(): void {
      this._dlgRef.close(DialogResult.no);

   }
}
