import { Injectable } from '@angular/core';
import { MyMonitoringService } from '../Services/logging.service';

interface IClient {
   closeWindow: () => void;
   getAppName: () => string;
}

declare var client: IClient;

@Injectable({
   providedIn: 'root'
})
export class BrowserLockService {
   constructor(
      private _loggingService: MyMonitoringService) {
   }

   public closeWindow(): void {
      if (typeof client !== 'undefined' && typeof client.closeWindow !== 'undefined') {
         try {
            client.closeWindow();
         }
         catch(e) {
            console.error('e');
            this.logToAppinsights(e)
         }
      }
      else {
         console.log('closeWindow was called outside a secure browser.');
      }
   }

   public getAppName(): string {
      if (typeof client !== 'undefined' && typeof client.getAppName !== 'undefined') {
         try {
            return client.getAppName();
         }
         catch(e) {
            console.error('e');
         }
      }
      return '';
   }

   public isSecureBrowser(): Promise<boolean> {
      //NOTE: This is temporary. Call client.isSecureBrowser and do token negotiation.
      return new Promise((resolve, reject) => {
         const appName: string = this.getAppName();
         if (appName === 'BrowserLock_win32' || appName === 'Pearson VUE Browser Lock_mac') {
            resolve(true);
         }
         resolve(false);
      });
   }

   private logToAppinsights(error: string) {
      this._loggingService.logTrace(error);
   }
}
