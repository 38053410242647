import { ActivatedRoute } from "@angular/router";
import { Injectable } from '@angular/core';
import { BrowserLockService } from "./browser-lock-service";
import { SignalRHub } from "./SignalRHub";

//TODO: The BrowserLockService should be moved out of the SignalRService to a more appropriate place.
// The SignalRService should be responsible solely for sending and receiving SignalR messages.

//TODO: The start method and close-lockdown functionality need unit tests.

@Injectable({
   providedIn: 'root'
})
export class SignalRService {

   private _examReadySubscribers: { (): void; }[] = [];

   constructor(private _signalRHub: SignalRHub, private _route: ActivatedRoute, private _browserLock: BrowserLockService) { }

   public startConnection(): Promise<void> {
      this._signalRHub.initialize();
      return this.start();
   }

   private start(): Promise<void> {
      return this._signalRHub.start()
         .then(() => {
            this._route.queryParams
               .subscribe(params => {
                  let slnk: string = params['symlink'];
                  if (slnk !== null && slnk !== undefined)
                     this.subscribe(slnk)
                        .then(() => {
                           this.addCloseLockdownListener();
                           this.addExamReadyListener();
                        })
                        .catch(() => {
                           console.log('Error: Failed to connect listener.');
                        })
               });
         })
         .catch((err: any) => console.log('SignalR connection error:' + err));
   }

   public addCloseLockdownListener = () => {
      this._signalRHub.on('closeLockdown', () => {
         this.handleCloseLockdownRequest();
      });
   }

   private handleCloseLockdownRequest(): void {
      this._route.queryParams
         .subscribe(params => {
            let slnk: string = params['symlink'];
            if (slnk !== null && slnk !== undefined) {
               this._signalRHub.invoke("AcknowlegeCloselockdown", slnk)
                  .then(() => {
                     this._browserLock.closeWindow();
                  })
                  .catch();
            }
         });
   }

   public addExamReadyListener(): void {
      this._signalRHub.on('ExamReady', () => {
         this.handleExamReadyNotification();
      });
   }

   public handleExamReadyNotification(): void {
      for (var i: number = 0; i < this._examReadySubscribers.length; i++) {
         this._examReadySubscribers[i]();
      }
   }

   public subscribeToExamReady(newMethod: () => void): void {
      this._examReadySubscribers.push(newMethod);
   }

   private subscribe(symlink: string): Promise<void> {
      return this._signalRHub.invoke("Subscribe", symlink);
   }
}
