import { Component, OnInit } from '@angular/core';
import { MyMonitoringService } from '../app/Services/logging.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: ['body {margin:0}']
})
export class AppComponent implements OnInit {
  title = 'app';

   constructor(private myMonitoringService: MyMonitoringService) {
  }

  ngOnInit(): void {
  }

  clickedTheApp(): void {
  }
}
