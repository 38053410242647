import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { environment } from "../../environments/environment";

export interface ISignalRHub {
   initialize(): void;
   start(): Promise<void>;
   on(methodName: string, newMethod: () => void): void;
   invoke(methodName: string, param1: string): Promise<void>;
}

@Injectable({
   providedIn: 'root'})export class SignalRHub implements ISignalRHub {
   private hubConnection!: signalR.HubConnection;

   constructor() {}

   initialize(): void {
      this.hubConnection = new signalR.HubConnectionBuilder()
         .withUrl(environment.ibtVendorServiceUrl + 'LockdownHub')
         .build();
   }

   start(): Promise<void> {
      return this.hubConnection.start();
   }

   on(methodName: string, newMethod: () => void): void {
      this.hubConnection.on(methodName, newMethod);
   }

   invoke(methodName: string, param1: string): Promise<void> {
      return this.hubConnection.invoke(methodName, param1);
   }
}
